import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { FaPen, FaPlus, FaQrcode } from "react-icons/fa6";
import {QRCodeCanvas} from 'qrcode.react';

import {
  getFloorList,
  getRoomList,
  upsertRoom,
  deleteRoom,
} from "../../services/api.service";
import { useAppContext } from "../../context/AppContextProvider";

import ConfirmDialog from "../../components/ConfirmDialog";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";

const ManageRoom = () => {
  const { userDetail, onOpenAlert } = useAppContext();
  const [openForm, setOpenForm] = useState(false);
  const [openPrintQR, setOpenPrintQR] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomDetail, setRoomDetail] = useState({});
  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  const canvasRef = useRef(null);

  const roomColumns = [
    { key: "id", title: "ID", render: (val, item, index) => index + 1 },
    { key: "name", title: "Name" },
    { key: "floor", title: "Lantai", render: (value) => value?.name },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          onEdit={() => {
            setOpenForm(true);
            setRoomDetail(item);
            onChange("name", item?.name);
            onChange("floor", item?.floor?.name);
          }}
          onDelete={() => {
            setOpenConfirm(true);
            setRoomDetail(item);
          }}
          onPrintQR={() => {
            setOpenPrintQR(true);
            setRoomDetail(item);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    onGetRoomList();
    onGetFloorList();
  }, []);

  const onGetRoomList = async () => {
    try {
      setFetchLoading(true);
      const response = await getRoomList();
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setRoomList(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetListRoom: ", error);
    }
  };

  const onGetFloorList = async () => {
    try {
      setFetchLoading(true);
      const response = await getFloorList();
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setFloorList(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetListFloor: ", error);
    }
  };

  const onUpsertRoom = async (data) => {
    if (deleteLoading) return;
    try {
      const request = {
        id: roomDetail?.id,
        name: data?.name || roomDetail?.name,
        floor_id: data?.floor.value || roomDetail?.floor?.id
      };

      setUpdateLoading(true);
      const response = await upsertRoom(request);
      if (!response.success) throw response.message;

      setUpdateLoading(false);
      setOpenForm(false);
      setValue("days", null);
      onGetRoomList();
    } catch (error) {
      setUpdateLoading(false);
      console.log("Error on onUpsertRoom: ", error);
    }
  };

  const onOpenForm = () => {
    if (roomList?.length >= userDetail?.config?.max_room) {
      onOpenAlert("error", "Max Room limit reached");
      return;
    }

    if (floorList?.length <= 0) {
      onOpenAlert("error", "Please create floor first");
      return;
    }

    reset();
    clearErrors();
    setOpenForm(true);
    setRoomDetail({});
  };

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  const onConfirmDelete = async () => {
    try {
      setDeleteLoading(true);
      const response = await deleteRoom(roomDetail?.id);
      if (!response.success) throw response.message;

      setDeleteLoading(false);
      setOpenConfirm(false);
      onGetRoomList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setDeleteLoading(false);
      onOpenAlert("error", error);
    }
  };

  const handlePrint = () => {
    const canvas = canvasRef.current.children[0];
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg h-max shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Room List</span>
          <Button
            label="Tambah"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={onOpenForm}
          />
        </div>
        <div className="p-4">
          <Table
            loading={fetchLoading}
            columns={roomColumns}
            dataSource={roomList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={currentPage}
            total={!fetchLoading ? roomList?.length : 0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
        <Modal
          open={openForm}
          onOpen={setOpenForm}
          className={{ container: "p-0" }}
        >
          <form
            onSubmit={handleSubmit(onUpsertRoom)}
            className="overflow-auto"
          >
            <div className="flex items-center px-4 py-3 text-white bg-sky-600">
              <div className="p-1 text-sm border border-white rounded-lg">
                <FaPen />
              </div>
              {roomDetail?.id ? (
                <>
                  <div className="ml-3 mr-1 text-lg">Edit</div>
                  <div className="flex items-center">
                    <h1 className="mx-1 text-sm">({roomDetail?.name})</h1>
                  </div>
                </>
              ) : (
                <div className="ml-3 mr-1 text-lg">Tambah</div>
              )}
            </div>
            <div className="px-8 py-6 flex flex-col gap-2">
              <div className="mb-5 gap-x-3">
                <Input
                  label="Name"
                  placeholder="Input Nama Ruangan"
                  className={{
                    label: "text-gray-700",
                    input: "h-10 pl-3",
                  }}
                  errorMessage={errors?.name?.message}
                  onInputChange={(e) => onChange("name", e.target.value)}
                  {...register("name")}
                />
              </div>
              <div className="mb-5 gap-x-3">
                <Select
                  label="Lantai"
                  placeholder="Pilih Lantai"
                  options={floorList}
                  className={{
                    label: "text-gray-700",
                    input: "h-10 pl-3 capitalize",
                    suffix: "text-sky-700",
                    option: "capitalize",
                  }}
                  errorMessage={errors?.floor?.message}
                  onInputChange={(e) => {
                    onChange("floor", e.name);
                    onChange("floor.value", e.id);
                  }}
                  {...register("floor")}
                />
              </div>
              <Button
                label="submit"
                isLoading={updateLoading}
                className="w-1/3 h-10 mt-8 ml-auto"
              />
            </div>
          </form>
        </Modal>
        <Modal
          open={openPrintQR}
          onOpen={setOpenPrintQR}
          className={{ container: "p-0 max-w-sm"}}
        >
          <div className="overflow-auto">
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
              <div className="p-1 text-sm border border-white rounded-lg">
                <FaQrcode />
              </div>
              <div className="ml-3 mr-1 text-lg">Download QR Code</div>
            </div>
            <div className="px-8 py-6 flex flex-col gap-2 items-center">
              <div className="mb-5 gap-x-3" ref={canvasRef}>
                <QRCodeCanvas
                  value={`${process.env.REACT_APP_BARCODE}/calendar?room=${roomDetail.id}`}
                  size={384}
                  includeMargin={true}
                  marginSize={10}
                />
              </div>
              <Button label="Download" onClickBtn={handlePrint} className="w-1/3 h-10 mt-8"/>
            </div>
          </div>
        </Modal>
        <ConfirmDialog
          open={openConfirm}
          onOpen={setOpenConfirm}
          className={{ container: "max-w-sm px-8 py-7" }}
          title="Delete"
          detail="Apakah anda yakin ingin menghapus data ini?"
          confirmText="Hapus"
          isLoading={deleteLoading}
          onConfirm={onConfirmDelete}
        />
      </div>
    </div>
  );
};

export default ManageRoom;
