import React from "react";
import { cn } from "../utils/helper";
import { LoadingIcon } from "../assets/icon/SvgIconComponents";

const Button = ({ label, icon, className, isLoading, onClickBtn }) => {
  return (
    <button
      type="submit"
      className={cn(
        `flex items-center justify-center gap-2 w-full h-12 rounded-lg capitalize bg-sky-600 hover:bg-sky-600/75 border-none text-sm text-white font-semibold
        outline-none transition active:focus:scale-95`, className
      )}
      onClick={(e) => onClickBtn?.(e)}
    >
      {isLoading && <LoadingIcon /> }
      {(!isLoading && icon) && icon}
      {label}
    </button>
  );
}

export default Button;