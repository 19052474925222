import { useNavigate } from "react-router-dom";
import { destroyToken, removeLocalStorage } from "../../services/jwt.service";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useAppContext } from "../../context/AppContextProvider";

const Logout = ({ open, onOpen }) => {
  const { userDetail, setStore } = useAppContext();
  const navigate = useNavigate();

  const onLogout = () => {
    destroyToken();
    removeLocalStorage("user");
    removeLocalStorage("activeModule");

    setStore({ activeModule: "" });
    setStore({ userDetail: {} });
    onOpen(false);
    navigate("/login");
  };

  return (
    <ConfirmDialog
      open={open}
      onOpen={onOpen}
      className={{ container: "max-w-sm px-8 py-7" }}
      title="Logout"
      detail="Apakah anda yakin ingin keluar dari akun ini?"
      onConfirm={onLogout}
    />
  );
};

export default Logout;
