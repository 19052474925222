import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { authResetPassword, forgotPasswordSSO } from "../../services/api.service";
import { cn } from "../../utils/helper";
import Input from "../../components/Input";
import Button from "../../components/Button";
import SliderComponent from "./../../components/Slider";
import AlertComponent from './../../components/Alert';
import SocialIcons from "./../../assets/json/social.json";
import LogoImg from "./../../assets/image/smart-office-logo-black.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, setValue, trigger, formState: { errors }, getValues, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (isLoading) return;

    if (token && searchParams.get("email")) {
      onSetNewPassword({ ...data, email: searchParams.get("email") });
    } else {
      onForgotPassword(data?.email);
    }
  };

  const onForgotPassword = async (email = "") => {
    try {
      setLoading(true);
      await forgotPasswordSSO(email);

      setLoading(false);
      setAlertOption({ open: true, type: "success", message: "Please check your email." });
    } catch (error) {
      setLoading(false);
      setAlertOption({ open: true, type: "error", message: error });
    }
  }

  const onSetNewPassword = async ({ email, password, confirmPassword }) => {
    try {
      const requestSSO = {
        token: token,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
      }

      setLoading(true);
      const response = await authResetPassword(requestSSO);
      if (!response.success) throw response.message;

      setLoading(false);
      setAlertOption({ open: true, type: "success", message: response.message });
      setTimeout(() => navigate("/login"), 1500);
    } catch (error) {
      setLoading(false);
      setAlertOption({ open: true, type: "error", message: error });
    }
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  const renderSocialIcon = () => {
    return SocialIcons.map((item, index) => {
      return (
        <a key={index} href={item.link} target="_blank" rel="noreferrer">
          <img src={require(`../../assets/svg/${item.icon}`)} alt={item.alt} />
        </a>
      )
    })
  }

  return (
    <div className="h-screen lg:flex font-outfit">
      <div className="hidden h-screen lg:w-[380px] lg:bg-[#27AE61] lg:flex lg:flex-col lg:justify-center lg:px-8">
        <img src={LogoImg} className="w-52" style={{ filter: "drop-shadow(0 0 10px rgba(255, 255, 255, 0.4))" }} alt="" />
        <SliderComponent />
        <div className="flex">{renderSocialIcon()}</div>
      </div>
      <div className="relative lg:w-[calc(100%-380px)] select-none">
        <AlertComponent
          type={alertOption?.type}
          open={alertOption?.open}
          message={alertOption?.message}
          onClose={setAlertOption}
        />
        <div className="p-8">
          <Link to="/">
            <img src={LogoImg} alt="" className="w-24" />
          </Link>
        </div>
        <div className="h-[calc(100%-96.14px)] flex justify-center items-center">
          <form className="w-[480px] text-[#7D818B]" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6 text-center">
              <h3 className="text-[#0F1628] text-4xl">{!token ? "Reset Password" : "Password Baru Anda"}</h3>
              <span className="text-base">Kami bantu pulihkan akunmu kembali</span>
            </div>
            {!token &&
              <div className="mb-5">
                <Input
                  label="Email"
                  icon={<FaEnvelope />}
                  placeholder="mail@website.com"
                  errorMessage={errors?.email?.message}
                  onInputChange={(e) => onChange('email', e.target.value)}
                  {...register("email", {
                    required: "Email wajib diisi!",
                    pattern: { value: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, message: "Alamat email tidak sesuai!" }
                  })}
                />
              </div>
            }
            {token &&
              <Fragment>
                <div className="mb-5">
                  <Input
                    type={showPassword ? "text" : "password"}
                    label="Password Baru"
                    icon={<FaLock />}
                    placeholder="Masukkan Password Baru"
                    errorMessage={errors?.password?.message}
                    showPassword={showPassword}
                    onShowPassword={setShowPassword}
                    onInputChange={(e) => {
                      onChange('password', e.target.value);
                      trigger("confirmPassword");
                    }}
                    {...register("password", {
                      required: "Password baru wajib diisi!",
                      validate: (val) => val === getValues("confirmPassword") || "Password baru tidak sama dengan Konfirmasi password!"
                    })}
                  />
                </div>
                <div className="mb-5">
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    label="Konfirmasi Password"
                    icon={<FaLock />}
                    placeholder="Masukkan Konfirmasi Password"
                    errorMessage={errors?.confirmPassword?.message}
                    showPassword={showConfirmPassword}
                    onShowPassword={setShowConfirmPassword}
                    onInputChange={(e) => {
                      onChange('confirmPassword', e.target.value);
                      trigger("password");
                    }}
                    {...register("confirmPassword", {
                      required: "Konfirmasi password wajib diisi!",
                      validate: (val) => val === getValues("password") || "Konfirmasi password tidak sama dengan Password baru!"
                    })}
                  />
                </div>
              </Fragment>
            }
            <div className={cn({ "mt-8": errors?.confirmPassword || errors?.email })}>
              <Button label={!token ? "Kirim email pemulihan" : "Continue"} isLoading={isLoading} className="bg-purple hover:bg-purple/75" />
            </div>
            {!token &&
              <div className="mt-4 text-sm text-center">
                <span>Kembali ke</span>&nbsp;
                <Link to="/login" className="text-[#4D4EC5]">Login</Link>
              </div>
            }
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;