import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FaAngleDown } from "react-icons/fa6";
import { LuLoader2 } from "react-icons/lu";
import { cn } from "../utils/helper";

const Select = forwardRef(({
  label,
  icon,
  placeholder = "Select Option",
  options = [],
  defaultValue,
  isLoading = false,
  errorMessage,
  onInputChange,
  className,
  ...rest
}, ref) => {
  const selectRef = useRef(undefined);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultValue?.name);

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    }
  })

  const onClickOutside = (e) => {
    if (selectRef.current?.contains(e.target)) return;
    setOpen(false);
  }

  return (
    <div ref={selectRef} className={cn("relative", className?.container)}>
      {label && <div className={cn("mb-1 text-sm text-gray-500", className?.label)}>{label}</div>}
      <div className="relative rounded-md shadow-sm" onClick={() => options?.length && setOpen(!open)}>
        {icon &&
          <div className={cn("absolute text-sky-600 text-base pointer-events-none left-4 top-1/2 -translate-y-1/2", className?.prefix, { "text-red-500": errorMessage, "text-gray-300": rest.disabled })}>
            {icon}
          </div>
        }
        <input
          {...rest}
          ref={ref}
          type="text"
          className={cn(
            `block w-full h-12 px-12 py-1.5 text-sm text-gray-500 border-0 rounded-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 
            focus:outline-none focus:ring-[#7D818B] cursor-pointer disabled:ring-0 disabled:placeholder:text-gray-300`,
            className?.input,
            { "placeholder:text-red-300 text-red-900 ring-red-300 focus:ring-red-500": errorMessage }
          )}
          placeholder={placeholder}
          value={selected}
          readOnly
        />
        <div className={cn("absolute text-sky-600 text-xs pointer-events-none right-4 top-1/2 -translate-y-1/2", className?.suffix, { "text-gray-300": rest.disabled })}>
          {isLoading ?
            <LuLoader2 className="animate-spin" />
            :
            <FaAngleDown className={cn({ "transition rotate-180": open, "transition -rotate-0": !open })} />
          }
        </div>
      </div>
      {open &&
        <div className="absolute z-10 min-w-full w-max py-1.5 mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-56 ring-1 ring-gray-300 focus:outline-none">
          {options?.map((option, index) => (
            <div
              key={index}
              className={cn("px-4 py-1 text-sm cursor-pointer hover:bg-sky-600 hover:text-white", className?.option)}
              onClick={() => {
                setOpen(false);
                onInputChange?.(option);
                if (defaultValue?.name) setSelected(option?.name);
              }}
            >
              {option?.name}
            </div>
          ))}
        </div>
      }
      {errorMessage && <p className="mt-1 -mb-5 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
});

export default Select;