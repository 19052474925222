import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaUserCheck, FaUserClock } from "react-icons/fa6";
import { useAppContext } from "../../../context/AppContextProvider";
import { cn } from "../../../utils/helper";
import { deleteEmployee, getEmployeeList, resetPasswordEmployee, verifiedEmployee } from "../../../services/api.service";
import Table from "../../../components/Table";
import TableActionButton from "../../../components/TableActionButton";
import Pagination from "../../../components/Pagination";
import Button from "../../../components/Button";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Modal from "../../../components/Modal";

const ManageEmployee = () => {
  const navigate = useNavigate();
  const { userDetail, onOpenAlert } = useAppContext();
  const [isEmployeeLoading, setIsEmployeeLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeCurrentPage, setEmployeeCurrentPage] = useState(1);
  const [dataDetail, setDataDetail] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoModalType, setInfoModalType] = useState('reset_password');
  const [isResetPasswordLoading, setIsResetPasswordLoading] = useState(false);
  const [isVerifiedLoading, setIsVerifiedLoading] = useState(false);

  const employeeColumns = [
    { key: "id", title: "No", render: (val, item, index) => index + 1 },
    { key: "name", title: "Nama Lengkap" },
    { key: "email", title: "Email" },
    { key: "phone_number", title: "No.Handphone" },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          hiddenPrintBarcode
          showResetPassword={item?.email_verified_at}
          showActivateUser={!item?.email_verified_at}
          onResetPassword={() => onResetPassword(item?.email)}
          onActivateUser={() => onVarifiedEmployee(item)}
          onEdit={() => navigate(`/owner/module-employee/employee-form/${item?.id}`)}
          onDelete={() => {
            setOpenConfirm(true);
            setDataDetail(item);
          }}
        />
      )
    }
  ]

  useEffect(() => {
    onGetEmployeeList();
  }, [])

  const onGetEmployeeList = async () => {
    try {
      setIsEmployeeLoading(true);
      const response = await getEmployeeList();
      if (!response.success) throw response.message;

      setIsEmployeeLoading(false);
      setEmployeeList(response?.data);
    } catch (error) {
      setIsEmployeeLoading(false);
      console.log("Error on onGetBranchList: ", error);
    }
  }

  const onResetPassword = async (email) => {
    setInfoModalType('reset_password');
    try {
      setOpenInfoModal(true);
      setIsResetPasswordLoading(true);
      const response = await resetPasswordEmployee(email);
      if (!response.success) throw response.message;

      setIsResetPasswordLoading(false);
    } catch (error) {
      setIsResetPasswordLoading(false);
      setOpenInfoModal(false);
      onOpenAlert("error", error);
    }
  }

  const onVarifiedEmployee = async (data) => {
    setInfoModalType('verified_employee');
    try {
      setOpenInfoModal(true);
      setIsVerifiedLoading(true);
      const response = await verifiedEmployee(data);
      if (!response.success) throw response.message;

      setIsVerifiedLoading(false);
      onGetEmployeeList();
    } catch (error) {
      setIsVerifiedLoading(false);
      setOpenInfoModal(false);
      onOpenAlert("error", error);
    }
  }

  const onConfirmDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteEmployee(dataDetail?.id);
      if (!response.success) throw response.message;

      setIsDeleteLoading(false);
      setOpenConfirm(false);
      onGetEmployeeList();
    } catch (error) {
      setIsDeleteLoading(false);
      console.log("Error on onConfirmDelete: ", error);
    }
  }

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Employee</span>
          <Button
            label="Tambah"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={() => {
              if (employeeList?.length >= userDetail?.config?.max_employee) {
                return onOpenAlert("error", "Max Employee limit reached");
              }
              return navigate("/owner/module-employee/employee-form")
            }}
          />
        </div>
        <div className="p-4">
          <Table
            loading={isEmployeeLoading}
            columns={employeeColumns}
            dataSource={employeeList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={employeeCurrentPage}
            total={!isEmployeeLoading ? employeeList?.length : 0}
            onPageChange={(page) => setEmployeeCurrentPage(page)}
          />
        </div>
      </div>
      <Modal open={openInfoModal} onOpen={setOpenInfoModal} className={{ container: "w-auto pr-8 pl-6 rounded-lg" }}>
        <div className="flex flex-col items-center gap-2">
          {(isVerifiedLoading || isResetPasswordLoading) ?
            <FaUserClock className="text-6xl text-yellow-500" />
            :
            <FaUserCheck className="text-6xl text-emerald-600" />
          }
          <div
            className={cn(
              "flex justify-center items-center text-xl font-semibold text-center text-gray-600 gap-2",
              { "pulse-loading": isVerifiedLoading || isResetPasswordLoading }
            )}
          >
            {infoModalType === 'verified_employee' &&
              <div>
                Activating employee {!isVerifiedLoading && "successfully"}<br />
                {!isVerifiedLoading &&
                  <span className="text-red-500">Please tell user to check their email.</span>
                }
              </div>
            }
            {infoModalType === 'reset_password' &&
              <>Sending reset password link {!isResetPasswordLoading && "successfully"}</>
            }
          </div>
          {(!isVerifiedLoading && !isResetPasswordLoading) &&
            <Button label="OK" className="h-10 mt-2" onClickBtn={() => setOpenInfoModal(false)} />
          }
        </div>
      </Modal>
      <ConfirmDialog
        open={openConfirm}
        onOpen={setOpenConfirm}
        className={{ container: "max-w-sm px-8 py-7" }}
        title="Delete"
        detail="Apakah anda yakin ingin menghapus data ini?"
        confirmText="Hapus"
        isBranchLoading={isDeleteLoading}
        onConfirm={onConfirmDelete}
      />
    </div>
  )
}

export default ManageEmployee;