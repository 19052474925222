import { useEffect } from "react";
import { cn } from './../utils/helper';
import { SuccessIcon, ErrorIcon } from "../assets/icon/SvgIconComponents";

const AlertComponent = ({ type = "success", open = false, message = "", className, onClose }) => {
  const isSuccess = type === "success";

  useEffect(() => {
    setTimeout(() => {
      if (open) onClose?.({ type, message, open: false });
    }, 3000);
  }, [open, onClose]);

  return (
    <div
      className={cn(
        "absolute z-20 left-1/2 -translate-x-1/2 transition-all duration-500",
        { "-top-12 opacity-0": !open, "top-12 opacity-100": open },
        className
      )}
    >
      <div
        className={cn(
          "flex gap-2 items-center bg-[#F6FFF9] border border-[#48C1B5] shadow-auto p-2 rounded-lg text-sm font-medium",
          { "bg-[#FFF5F3] border border-[#F4B0A1]": !isSuccess }
        )}
      >
        {isSuccess ? <SuccessIcon /> : <ErrorIcon />}
        {message && <span className="mr-1 leading-none">{message}</span>}
      </div>
    </div>
  );
}

export default AlertComponent;