import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../../context/AppContextProvider";
import { createUpdateEmployee, getEmployeeDetail } from "../../../services/api.service";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import LoadingBar from "../../../components/LoadingBar";
import TextArea from "../../../components/TextArea";

const EmployeeForm = () => {
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const { onOpenAlert } = useAppContext();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const { register, setValue, formState: { errors }, handleSubmit } = useForm();

  useEffect(() => {
    onFetchEmployeeFormApi();
  }, [])

  const onFetchEmployeeFormApi = async () => {
    try {
      setIsFormLoading(true);
      if (!employeeId) {
        return setIsFormLoading(false);
      }
      const employeeDetailRes = await getEmployeeDetail(employeeId);

      setIsFormLoading(false);
      onSetDataDetail(employeeDetailRes?.data);
    } catch (error) {
      setIsFormLoading(false);
      console.log("Error on onFetchEmployeeFormApi: ", error);
    }
  }

  const onSetDataDetail = (data = {}) => {
    if (!employeeId) return;

    setDataDetail(data);
    setValue("fullName", data?.name);
    setValue("email", data?.email);
    setValue("phoneNumber", data?.phone_number);
    setValue("position", data?.level?.name);
    setValue("position.id", data?.level?.id);
    setValue("address", data?.address);
  }

  const onSubmitEmployee = async (data) => {
    try {
      setIsSubmitLoading(true);
      const request = {
        id: employeeId,
        name: data?.fullName,
        email: data?.email,
        phoneNumber: data?.phoneNumber,
        position: data?.position?.id,
        address: data?.address
      }
      const response = await createUpdateEmployee(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      navigate("/owner/module-employee/employee");
    } catch (error) {
      setIsSubmitLoading(false);
      onOpenAlert("error", error);
    }
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg shadow-3xl">
        <div className="flex items-center px-2 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <div className="p-4 cursor-pointer" onClick={() => navigate("/owner/module-employee/employee")} >
            <FaArrowLeft />
          </div>
          <span>{employeeId ? "Edit" : "Add"} Form Employee</span>
        </div>
        <LoadingBar isLoading={isFormLoading} />
        <form onSubmit={handleSubmit(onSubmitEmployee)} className="p-6">
          <div className="grid grid-cols-4 gap-4 mb-5">
            <Input
              label="Nama Lengkap"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              placeholder="Masukkan Nama Lengkap"
              errorMessage={errors?.fullName?.message}
              onInputChange={(e) => onChange('fullName', e.target.value)}
              {...register("fullName", { required: "Nama Lengkap wajib diisi!" })}
            />
            <Input
              label="Email"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              placeholder="Masukkan Email"
              errorMessage={errors?.email?.message}
              onInputChange={(e) => onChange('email', e.target.value)}
              {...register("email", {
                required: "Email wajib diisi!",
                pattern: { value: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, message: "Alamat email tidak sesuai!" }
              })}
            />
            <Input
              label="No. Handphone"
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3"
              }}
              placeholder="Ch. 628***"
              errorMessage={errors?.phoneNumber?.message}
              onInputChange={(e) => onChange('phoneNumber', e.target.value)}
              {...register("phoneNumber", { required: "No. Handphone wajib diisi!", pattern: { value: /^[0-9]*$/, message: "No. Handphone tidak sesuai!" } })}
            />
            <Select
              label="Posisi"
              placeholder="Pilih Posisi"
              options={[
                { name: 'employee', id: 3 },
                { name: 'receptionist', id: 4 },
              ]}
              className={{
                label: "text-gray-700",
                input: "h-10 pl-3 capitalize",
                option: "capitalize"
              }}
              errorMessage={errors?.position?.message}
              onInputChange={(e) => {
                onChange('position', e.name);
                onChange('position.id', e.id);
              }}
              {...register("position", { required: "Posisi wajib diisi!" })}
            />
          </div>
          <TextArea
            label="Alamat"
            placeholder="Masukkan Alamat"
            className={{
              label: "text-gray-700",
              input: "pl-3 resize-none"
            }}
            errorMessage={errors?.address?.message}
            onInputChange={(e) => onChange('address', e.target.value)}
            {...register("address")}
          />
          <div className="flex items-center gap-4 mt-8">
            <Button label="save" isLoading={isSubmitLoading} className="w-40 h-10 px-8" />
            <Button
              label="discard"
              className="w-40 h-10 px-8 text-gray-800 bg-gray-200 hover:bg-gray-500 hover:text-white"
              onClickBtn={() => navigate("/owner/module-employee/employee")}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmployeeForm;