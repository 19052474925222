import React from "react";
import { cn } from "../utils/helper";
import CircleIcon from "../assets/svg/circle.svg";

import {
  FaUserGroup,
  FaUsersViewfinder,
  FaUsersRectangle,
  FaCalendarDays
} from "react-icons/fa6";

const CardModule = ({ title, className, display}) => {
  const icons = {
    employee: <FaUserGroup />,
    facility: <FaUsersViewfinder />,
    "meeting room management system": <FaUsersRectangle />,
    calendar: <FaCalendarDays />
  };

  const colors = {
    employee: "from-sky-300 to-blue-600",
    facility: "from-emerald-300 to-teal-600",
    "meeting room management system": "from-indigo-300 from-pink-600",
    calendar: "from-sky-300 to-blue-600",
  };
  return (
    <>
      {colors[title] && (
        <div
          className={cn("relative min-w-[310px] flex flex-col gap-2 bg-gradient-to-tr p-10 rounded-lg shadow", className?.container, colors[title])}
        >
          <img
            alt=""
            src={CircleIcon}
            className="absolute bottom-0 right-0 h-full"
          />
          <div className="flex flex-col items-center justify-between">
            {icons[title] && (
              <div className={cn("text-2xl text-white", className?.icon)}>
                {icons[title]}
              </div>
            )}
            <div className="text-lg font-medium text-white text-center">{display}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardModule;
