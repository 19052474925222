import { Fragment, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaKey } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { useAppContext } from './../../context/AppContextProvider';
import { destroyToken, getSSOToken, removeLocalStorage } from "../../services/jwt.service";
import { authChangePassword } from "../../services/api.service";
import Modal from '../../components/Modal';
import Input from "../../components/Input";
import Button from "../../components/Button";

const ChangePassword = ({ open, onOpen }) => {
  const navigate = useNavigate();
  const { onOpenAlert } = useAppContext();
  const [isLoading, setLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, trigger, formState: { errors }, setValue, getValues, clearErrors, handleSubmit } = useForm();

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  const onSubmitForm = async (data) => {
    if (isLoading) return;

    try {
      const { currentPassword = "", password = "", confirmPassword = "" } = data;
      const request = {
        current_password: currentPassword,
        new_password: password,
        new_password_confirmation: confirmPassword,
        sso_token: getSSOToken() || ""
      }

      setLoading(true);
      const response = await authChangePassword(request);
      if (!response?.success) throw response.message;

      setLoading(false);
      onOpenAlert("success", response.message);
      destroyToken();
      removeLocalStorage("user");
      navigate("/login");
    } catch (error) {
      setLoading(false);
      onOpenAlert("error", error);
    }
  }

  const handleOnClose = (input) => {
    clearErrors();
    onOpen(input)
  }

  return (
    <Fragment>
      <Modal open={open} onOpen={handleOnClose} className={{ container: "p-0" }}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="flex items-center gap-3 px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg"><FaKey /></div>
            <div className="text-lg">Change Password</div>
          </div>
          <div className="px-8 py-6">
            <div className="mb-5">
              <Input
                type={showCurrentPassword ? "text" : "password"}
                label="Current Password"
                placeholder="xxxxxxxx"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                  suffix: "text-sky-700",
                }}
                errorMessage={errors?.currentPassword?.message}
                showPassword={showCurrentPassword}
                onShowPassword={setShowCurrentPassword}
                onInputChange={(e) => onChange('currentPassword', e.target.value)}
                {...register("currentPassword", { required: "Current password wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Input
                type={showPassword ? "text" : "password"}
                label="New Password"
                placeholder="xxxxxxxx"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                  suffix: "text-sky-700",
                }}
                errorMessage={errors?.password?.message}
                showPassword={showPassword}
                onShowPassword={setShowPassword}
                onInputChange={(e) => {
                  onChange('password', e.target.value);
                  trigger("confirmPassword");
                }}
                {...register("password", {
                  required: "Password wajib diisi!",
                  validate: (val) => val === getValues("confirmPassword") || "Password tidak sama dengan Konfirmasi password!"
                })}
              />
            </div>
            <div className="mb-5">
              <Input
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                placeholder="xxxxxxxx"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                  suffix: "text-sky-700",
                }}
                errorMessage={errors?.confirmPassword?.message}
                showPassword={showConfirmPassword}
                onShowPassword={setShowConfirmPassword}
                onInputChange={(e) => {
                  onChange('confirmPassword', e.target.value);
                  trigger("password");
                }}
                {...register("confirmPassword", {
                  required: "Confirm password wajib diisi!",
                  validate: (val) => val === getValues("password") || "Confirm password tidak sama dengan Password baru!"
                })}
              />
            </div>
            <Button label="Submit" isLoading={isLoading} className="w-1/3 h-10 mt-8 ml-auto" />
          </div>
        </form>
      </Modal>
    </Fragment>
  )
}

export default ChangePassword;