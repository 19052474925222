import React, { useCallback, useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import moment from "moment";
import "moment/locale/id"; // Importing Indonesian locale

import { FaCalendarDays } from "react-icons/fa6";

import Input from "./Input";
import Modal from "./Modal";
import Button from "./Button";
import Select from "./Select";
import InputDateTime from "./InputDateTime";
import { LoadingIcon } from "../assets/icon/SvgIconComponents";

import { removeLocalStorage } from "../services/jwt.service";

import {
  getMeetingRoomList,
  getRoomList,
  upsertMeetingRoom,
  deleteMeetingRoom
} from "../services/api.service";

// Make sure to initialize Moment.js locale
moment.locale("id");

const localizer = momentLocalizer(moment);
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const ExampleCalendar = ({userDetail, onOpenAlert, roomID}) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [roomList, setRoomList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeDate, setActiveDate] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(true);

  const inputStartDateTimeRef = useRef(undefined);
  const inputEndDateTimeRef = useRef(undefined);

  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  const onCloseModal = (isOpen) => {
    setSelectedEvent({});
    setOpenModal(isOpen);
  };

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      reset();
      clearErrors();
      setOpenModal(true);
      setIsReadOnly(false);
      setActiveDate({ start, end });
    },
    [setEvents]
  );

  const handleSelectEvent = useCallback(
    (event) => {
      setSelectedEvent(event);
      reset();
      clearErrors();

      onChange("title", event?.title);
      onChange("room", event?.resource?.room?.name);
      onChange("start", moment(event?.start).format(DATE_FORMAT));
      onChange("end", moment(event?.end).format(DATE_FORMAT));
      setActiveDate({
        start: new Date(event?.start),
        end: new Date(event?.end),
      });

      const isReadOnly = userDetail.userId !==  event?.resource?.employee?.id
      setIsReadOnly(isReadOnly);
      setOpenModal(true);
    },
    [setSelectedEvent, setOpenModal]
  );

  const onGetEventList = async () => {
    try {
      setLoading(true);
      const response = await getMeetingRoomList();
      if (!response.success) throw response.message;

      const eventsData = response?.data.map((e) => ({
        id: e.id,
        title: e.title,
        start: new Date(e.start_date),
        end: new Date(e.end_date),
        resource: {
          employee: e.employee,
          room: e.room,
        },
      }));

      setLoading(false);
      setEvents(eventsData);
    } catch (error) {
      setLoading(false);
      console.log("Error on onGetListBuilding: ", error);
    }
  };

  const onGetRoomList = async () => {
    try {
      setLoading(true);
      const response = await getRoomList();
      if (!response.success) throw response.message;

      setLoading(false);
      setRoomList(response.data);
    } catch (error) {
      setLoading(false);
      console.log("Error on onGetRoomList: ", error);
    }
  };

  const onUpsertEvent = async (data) => {
    try {
      const roomID = (typeof data?.room === 'string') ? roomList.find((r) => r.name === data?.room)?.id : data?.room.value || selectedEvent?.room_id
      const request = {
        id: selectedEvent?.id,
        title: data?.title || selectedEvent?.title,
        start_date: data?.start || selectedEvent?.start_date,
        end_date: data?.end || selectedEvent?.end_date,
        room_id: roomID,
      };

      setLoading(true);
      const response = await upsertMeetingRoom(request);
      if (!response.success) throw response.message;

      removeLocalStorage('room-id');
      setLoading(false);
      setOpenModal(false);
      onGetEventList();
    } catch (error) {
      setLoading(false);
      onOpenAlert("error", error);
      console.log("Error on onUpsertEvent: ", error);
    }
  };

  const onDeleteEvent = async () => {
    try {
      setLoading(true);
      const response = await deleteMeetingRoom(selectedEvent?.id);
      if (!response.success) throw response.message;

      setLoading(false);
      onGetEventList();
      onCloseModal(false);
      onOpenAlert("success", response.message);
    } catch (error) {
      setLoading(false);
      onCloseModal(false);
      onOpenAlert("error", error);
      console.log("Error on onDeleteEvent: ", error);
    }
  }

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  useEffect(() => {
    onGetRoomList();
    onGetEventList();
  }, []);

  useEffect(() => {
    if (!roomList.length || !roomID) {
      return;
    }
    const room = roomList.find(r => r.id == roomID);
    if (!room) {
      return onOpenAlert("error", "Room Not Found");
    }

    reset();
    clearErrors();
    setOpenModal(true);
    setIsReadOnly(false);
    onChange("room", room?.name);

    const currentDate = new Date();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate.setMinutes(currentDate.getMinutes() + 30));
    setActiveDate({ start: startDate, end: endDate});
  }, [roomID, roomList])

  return (
    <>
      <Calendar
        dayLayoutAlgorithm={"no-overlap"}
        localizer={localizer}
        events={events}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        startAccessor="start"
        endAccessor="end"
        defaultView="day"
        views={["month", "day"]}
      />

      <Modal
        open={openModal}
        onOpen={onCloseModal}
        className={{ container: "p-0" }}
      >
        <form onSubmit={handleSubmit(onUpsertEvent)} className="overflow-auto">
          <div className="flex items-center px-4 py-3 text-white bg-sky-600">
            <div className="p-1 text-sm border border-white rounded-lg">
              <FaCalendarDays />
            </div>
            {selectedEvent?.id ? (
              <>
                <div className="ml-3 mr-1 text-lg">Edit</div>
                <div className="flex items-center">
                  <h1 className="mx-1 text-sm">({selectedEvent?.title})</h1>
                </div>
              </>
            ) : (
              <div className="ml-3 mr-1 text-lg">Tambah</div>
            )}
          </div>
          <div className="px-8 py-6 flex flex-col gap-2">
            <div className="mb-5 gap-x-3">
              <Input
                disabled={isReadOnly}
                label="Event"
                placeholder="Masukan Nama Acara"
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3",
                }}
                errorMessage={errors?.title?.message}
                onInputChange={(e) => onChange("title", e.target.value)}
                {...register("title")}
              />
            </div>
            <div className="mb-5 gap-x-3">
              <Select
                disabled={isReadOnly}
                label="Room"
                placeholder="Pilih Ruangan"
                options={roomList}
                className={{
                  label: "text-gray-700",
                  input: "h-10 pl-3 capitalize",
                  suffix: "text-sky-700",
                  option: "capitalize",
                }}
                errorMessage={errors?.room?.message}
                onInputChange={(e) => {
                  onChange("room", e.name);
                  onChange("room.value", e.id);
                }}
                {...register("room")}
              />
            </div>
            <div className="mb-5 gap-x-3">
              <InputDateTime
                disabled={isReadOnly}
                ref={inputStartDateTimeRef}
                label={"Start Date"}
                value={activeDate.start}
                onInputChange={(e) => {
                  onChange("start", moment(e).format(DATE_FORMAT));
                  setActiveDate({
                    start: moment(e).format(DATE_FORMAT),
                    end: activeDate.end,
                  });
                }}
                {...register("start")}
              />
            </div>
            <div className="mb-5 gap-x-3">
              <InputDateTime
                disabled={isReadOnly}
                ref={inputEndDateTimeRef}
                label={"End Date"}
                value={activeDate.end}
                minDate={activeDate.start}
                onInputChange={(e) => {
                  onChange("end", moment(e).format(DATE_FORMAT));
                  setActiveDate({
                    start: activeDate.start,
                    end: moment(e).format(DATE_FORMAT),
                  });
                }}
                {...register("end")}
              />
            </div>

            {!isReadOnly && (
              <div className="flex justify-between">
                {selectedEvent.id && (
                  <div
                    className={
                      "flex items-center justify-center gap-2 rounded-lg capitalize bg-rose-500 hover:bg-rose-600/75 border-none text-sm text-white font-semibold outline-none transition active:focus:scale-95 w-1/3 h-10 mt-8 mr-auto hover:cursor-pointer"
                    }
                    onClick={onDeleteEvent}
                  >
                    {loading && <LoadingIcon /> } Delete
                  </div>
                )}
                <Button
                  label="submit"
                  isLoading={loading}
                  className="w-1/3 h-10 mt-8 ml-auto"
                />
              </div>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ExampleCalendar;
