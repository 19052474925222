import React, { createContext, useContext, useReducer } from 'react';
import { getLocalStorage, saveLocalStorage } from '../services/jwt.service';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [store, setStore] = useReducer((prev, next) => ({ ...prev, ...next }), {
    userDetail: getLocalStorage("user") || {},
    activeModule: getLocalStorage("activeModule") || "",
    alertOption: {},
  });

  const setUserDetail = (userDetail) => {
    if (!userDetail) return;

    setStore({ userDetail });
    saveLocalStorage("user", userDetail);
  }

  const setActiveModule = (activeModule) => {
    if (!activeModule) return;

    setStore({ activeModule });
    saveLocalStorage("activeModule", activeModule);
  }

  const onOpenAlert = (type, message) => {
    if (!type && !message) return;
    setStore({ alertOption: { open: true, type, message } });
  }

  const onCloseAlert = (alertOption = {}) => {
    setStore({ alertOption });
  }

  return (
    <AppContext.Provider value={{ ...store, setStore, setUserDetail, setActiveModule, onOpenAlert, onCloseAlert }}>
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
export default AppContextProvider;