import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { cn } from "../utils/helper";

const Pagination = ({ currentPage = 1, pageSize = 10, total, onPageChange }) => {
  const pages = total > pageSize ? Math.ceil(total / pageSize) : 1;

  const renderPageBtn = () => {
    return Array.from({ length: pages }).map((_, index) => {
      const number = index + 1;
      return (
        <button
          key={index}
          className={cn(
            "text-xs px-2.5 py-1 bg-white text-black rounded hover:bg-gray-200 hover:ring-1 hover:ring-gray-200",
            {
              "ring-1 ring-sky-700 text-sky-700 font-semibold hover:bg-white hover:ring-1 hover:ring-sky-700": number === currentPage
            }
          )}
          onClick={() => onPageChange?.(number)}
        >
          {number}
        </button>
      )
    })
  }

  if (!total) return;

  return (
    <div className="flex justify-center gap-2">
      <button
        className={cn(
          "text-xs px-2 py-1 bg-white text-gray-700 rounded hover:bg-gray-100 hover:ring-1 hover:ring-gray-100",
          { "opacity-50 cursor-not-allowed": currentPage === 1 }
        )}
        onClick={() => {
          if (currentPage === 1) return;
          onPageChange?.(currentPage - 1);
        }}
      >
        <FaAngleLeft />
      </button>
      {renderPageBtn()}
      <button
        className={cn(
          "text-xs px-2 py-1 bg-white text-gray-700 rounded hover:bg-gray-100 hover:ring-1 hover:ring-gray-100",
          { "opacity-50 cursor-not-allowed": currentPage === pages }
        )}
        onClick={() => {
          if (currentPage === pages) return;
          onPageChange?.(currentPage + 1);
        }}
      >
        <FaAngleRight />
      </button>
    </div>
  );
}

export default Pagination;