import { Fragment } from 'react';
import { Tooltip } from 'react-tooltip';
import { FaPen, FaTrashCan, FaEye, FaKey, FaUnlock, FaQrcode } from "react-icons/fa6";

const TableActionButton = ({
  showResetPassword = false, showActivateUser = false, hiddenView = false, hiddenEdit = false, hiddenDelete = false, hiddenPrintBarcode = false,
  onResetPassword, onActivateUser, onView, onEdit, onDelete, onPrintQR
}) => {
  return (
    <div className="flex justify-center gap-2">
      {showResetPassword &&
        <Fragment>
          <Tooltip place="top" anchorSelect="#reset-password" content="Reset Password" />
          <div
            id="reset-password"
            className="p-1.5 text-gray-500 text-xs rounded-lg bg-gray-100 cursor-pointer hover:bg-gray-500 hover:text-white"
            onClick={onResetPassword}
          >
            <FaKey />
          </div>
        </Fragment>
      }
      {showActivateUser &&
        <Fragment>
          <Tooltip place="top" anchorSelect="#active-user" content="Activate User" />
          <div
            id="active-user"
            className="p-1.5 text-blue-500 text-xs rounded-lg bg-blue-100 cursor-pointer hover:bg-blue-500 hover:text-white"
            onClick={onActivateUser}
          >
            <FaUnlock />
          </div>
        </Fragment>
      }
      {!hiddenView &&
        <Fragment>
          <Tooltip place="top" anchorSelect="#view-data" content="View Data" />
          <div
            id="view-data"
            className="p-1.5 text-teal-500 text-xs rounded-lg bg-teal-100 cursor-pointer hover:bg-teal-500 hover:text-white"
            onClick={onView}
          >
            <FaEye />
          </div>
        </Fragment>
      }
      {!hiddenEdit &&
        <Fragment>
          <Tooltip place="top" anchorSelect="#edit-data" content="Edit Data" />
          <div
            id="edit-data"
            className="p-1.5 text-amber-500 text-xs rounded-lg bg-amber-100 cursor-pointer hover:bg-amber-500 hover:text-white"
            onClick={onEdit}
          >
            <FaPen />
          </div>
        </Fragment>
      }
      {!hiddenDelete &&
        <Fragment>
          <Tooltip place="top" anchorSelect="#delete-data" content="Delete Data" />
          <div
            id="delete-data"
            className="p-1.5 text-red-500 text-xs rounded-lg bg-red-100 cursor-pointer hover:bg-red-500 hover:text-white"
            onClick={onDelete}
          >
            <FaTrashCan />
          </div>
        </Fragment>
      }
      {!hiddenPrintBarcode &&
      <Fragment>
        <Tooltip place="top" anchorSelect="#print-qr-code" content="Print QR Code" />
        <div
          id="print-qr-code"
          className="p-1.5 text-orange-500 text-xs rounded-lg bg-orange-100 cursor-pointer hover:bg-orange-500 hover:text-white"
          onClick={onPrintQR}
        >
          <FaQrcode />
        </div>
      </Fragment>
      }
    </div>
  )
}

export default TableActionButton;