import React, { forwardRef } from 'react';
import { FaEyeSlash, FaEye } from "react-icons/fa6";
import { cn } from "../utils/helper";

const Input = forwardRef(({ type = "text", label, icon, placeholder, errorMessage, showPassword, onInputChange, onShowPassword, className, ...rest }, ref) => {
  const isFileInput = type === "file";

  const onClickFile = () => {
    document.getElementById("inputFile")?.click();
  }

  return (
    <div className={className?.container}>
      {label && <div className={cn("mb-1 text-sm text-gray-500", className?.label)}>{label}</div>}
      <div className="relative rounded-md shadow-sm">
        {icon &&
          <div className={cn("absolute text-purple text-base pointer-events-none left-4 top-1/2 -translate-y-1/2", { "text-red-500": errorMessage }, className?.prefix)}>
            {icon}
          </div>
        }
        {isFileInput &&
          <button type="button" className="absolute bottom-0 z-10 h-full px-3.5 text-sm text-white rounded-l-lg w-fit bg-sky-600" onClick={onClickFile}>
            Choose File
          </button>
        }
        <input
          {...rest}
          id={isFileInput ? "inputFile" : undefined}
          ref={ref}
          type={type}
          className={cn(
            "block w-full h-12 py-1.5 pr-1.5 text-sm text-gray-500 border-0 rounded-lg pl-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-[#7D818B]",
            {
              "placeholder:text-red-300 text-red-900 ring-red-300 focus:ring-red-500": errorMessage,
              "cursor-pointer": isFileInput
            },
            className?.input
          )}
          placeholder={placeholder}
          autoComplete="new-password"
          onChange={(e) => onInputChange?.(e)}
        />
        {onShowPassword &&
          <div
            className={cn("absolute p-1 right-4 top-1/2 -translate-y-1/2 text-base text-purple cursor-pointer", className?.suffix, { "text-red-500": errorMessage })}
            onClick={() => onShowPassword(!showPassword)}
          >
            {showPassword ? <FaEye /> : <FaEyeSlash />}
          </div>
        }
      </div>
      {errorMessage && <p className="mt-1 -mb-5 text-sm text-red-600">{errorMessage}</p>}
    </div>
  );
});

export default Input;