import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPen, FaPlus } from "react-icons/fa6";

import {
  getBuildingList,
  getFloorList,
  upsertFloor,
  deleteFloor,
} from "../../services/api.service";
import { useAppContext } from "../../context/AppContextProvider";

import ConfirmDialog from "../../components/ConfirmDialog";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";

const ManageFloor = () => {
  const { userDetail, onOpenAlert } = useAppContext();
  const [openForm, setOpenForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [floorDetail, setFloorDetail] = useState({});
  const {
    register,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm();

  const floorColumns = [
    { key: "id", title: "ID", render: (val, item, index) => index + 1 },
    { key: "name", title: "Name" },
    { key: "building", title: "Gedung", render: (value) => value?.name },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          hiddenPrintBarcode
          onEdit={() => {
            setOpenForm(true);
            setFloorDetail(item);
            onChange("name", item?.name);
            onChange("building", item?.building?.name);
          }}
          onDelete={() => {
            setOpenConfirm(true);
            setFloorDetail(item);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    onGetFloorList();
    onGetBuildingList();
  }, []);

  const onGetFloorList = async () => {
    try {
      setFetchLoading(true);
      const response = await getFloorList();
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setFloorList(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetListFloor: ", error);
    }
  };

  const onGetBuildingList = async () => {
    try {
      setFetchLoading(true);
      const response = await getBuildingList();
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setBuildingList(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetListBuilding: ", error);
    }
  };

  const onUpsertFloor = async (data) => {
    if (deleteLoading) return;
    try {
      const request = {
        id: floorDetail?.id,
        name: data?.name || floorDetail?.name,
        building_id: data?.building.value || floorDetail?.building?.id
      };

      setUpdateLoading(true);
      const response = await upsertFloor(request);
      if (!response.success) throw response.message;

      setUpdateLoading(false);
      setOpenForm(false);
      setValue("days", null);
      onGetFloorList();
    } catch (error) {
      setUpdateLoading(false);
      console.log("Error on onUpsertFloor: ", error);
    }
  };

  const onOpenForm = () => {
    if (floorList?.length >= userDetail?.config?.max_floor) {
      onOpenAlert("error", "Max Floor limit reached");
      return;
    }

    if (buildingList?.length <= 0) {
      onOpenAlert("error", "Please create building first");
      return;
    }

    reset();
    clearErrors();
    setOpenForm(true);
    setFloorDetail({});
  };

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  const onConfirmDelete = async () => {
    try {
      setDeleteLoading(true);
      const response = await deleteFloor(floorDetail?.id);
      if (!response.success) throw response.message;

      setDeleteLoading(false);
      setOpenConfirm(false);
      onGetFloorList();
      onOpenAlert("success", response.message);
    } catch (error) {
      setDeleteLoading(false);
      onOpenAlert("error", error);
    }
  };

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg h-max shadow-3xl">
        <div className="flex justify-between p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          <span>Floor List</span>
          <Button
            label="Tambah"
            icon={<FaPlus />}
            className="h-8 px-3 bg-white w-fit ring-1 ring-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white"
            onClickBtn={onOpenForm}
          />
        </div>
        <div className="p-4">
          <Table
            loading={fetchLoading}
            columns={floorColumns}
            dataSource={floorList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={currentPage}
            total={!fetchLoading ? floorList?.length : 0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
        <Modal
          open={openForm}
          onOpen={setOpenForm}
          className={{ container: "p-0" }}
        >
          <form
            onSubmit={handleSubmit(onUpsertFloor)}
            className="overflow-auto"
          >
            <div className="flex items-center px-4 py-3 text-white bg-sky-600">
              <div className="p-1 text-sm border border-white rounded-lg">
                <FaPen />
              </div>
              {floorDetail?.id ? (
                <>
                  <div className="ml-3 mr-1 text-lg">Edit</div>
                  <div className="flex items-center">
                    <h1 className="mx-1 text-sm">({floorDetail?.name})</h1>
                  </div>
                </>
              ) : (
                <div className="ml-3 mr-1 text-lg">Tambah</div>
              )}
            </div>
            <div className="px-8 py-6 flex flex-col gap-2">
              <div className="mb-5 gap-x-3">
                <Input
                  label="Name"
                  placeholder="Input Nama Lantai"
                  className={{
                    label: "text-gray-700",
                    input: "h-10 pl-3",
                  }}
                  errorMessage={errors?.name?.message}
                  onInputChange={(e) => onChange("name", e.target.value)}
                  {...register("name")}
                />
              </div>
              <div className="mb-5 gap-x-3">
                <Select
                  label="Gedung"
                  placeholder="Pilih Gedung"
                  options={buildingList}
                  className={{
                    label: "text-gray-700",
                    input: "h-10 pl-3 capitalize",
                    suffix: "text-sky-700",
                    option: "capitalize",
                  }}
                  errorMessage={errors?.building?.message}
                  onInputChange={(e) => {
                    onChange("building", e.name);
                    onChange("building.value", e.id);
                  }}
                  {...register("building")}
                />
              </div>
              <Button
                label="submit"
                isLoading={updateLoading}
                className="w-1/3 h-10 mt-8 ml-auto"
              />
            </div>
          </form>
        </Modal>
        <ConfirmDialog
          open={openConfirm}
          onOpen={setOpenConfirm}
          className={{ container: "max-w-sm px-8 py-7" }}
          title="Delete"
          detail="Apakah anda yakin ingin menghapus data ini?"
          confirmText="Hapus"
          isLoading={deleteLoading}
          onConfirm={onConfirmDelete}
        />
      </div>
    </div>
  );
};

export default ManageFloor;
