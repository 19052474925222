import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaBuilding, FaStairs, FaHouse } from "react-icons/fa6";
import { useAppContext } from "../../context/AppContextProvider";
import { getAnalytics } from "../../services/api.service";
import CardCount from "../../components/CardCount";

const DashboardEmployee = () => {
  const navigate = useNavigate();
  const { userDetail } = useAppContext();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});

  const isSuperAdmin = userDetail?.levelName === "superadmin";

  useEffect(() => {
    onGetAnalytics();
  }, []);

  const onGetAnalytics = async () => {
    try {
      setFetchLoading(true);
      const response = await getAnalytics('module-facility');
      if (!response.success) throw response.message;

      setFetchLoading(false);

      console.log("response.data", response.data)

      setAnalytics(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetAnalytics: ", error);
    }
  };

  if (isSuperAdmin) {
    return navigate("/home");
  }

  return (
    <div className="p-6">
      <div>
        <div className="flex gap-6">
          <CardCount
            title="Jumlah Gedung"
            value={analytics?.buildingCount || 0}
            icon={<FaBuilding />}
            isLoading={fetchLoading}
            className={{
              container: "mb-6 from-emerald-300 to-teal-600",
            }}
          />
          <CardCount
            title="Jumlah Lantai"
            value={analytics?.floorCount || 0}
            icon={<FaStairs />}
            isLoading={fetchLoading}
            className={{
              container: "mb-6 from-emerald-300 to-teal-600",
            }}
          />
          <CardCount
            title="Jumlah Ruangan"
            value={analytics?.roomCount || 0}
            icon={<FaHouse />}
            isLoading={fetchLoading}
            className={{
              container: "mb-6 from-emerald-300 to-teal-600",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardEmployee;
