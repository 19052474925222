import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaHouseLaptop } from "react-icons/fa6";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

import { useAppContext } from "../../context/AppContextProvider";
import { getAnalytics, getReportMRM } from "../../services/api.service";
import CardCount from "../../components/CardCount";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Table from "../../components/Table";
import { render } from "@testing-library/react";

const monthsOptions = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];

const DashboardMrm = () => {
  const navigate = useNavigate();
  const { userDetail } = useAppContext();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchReportLoading, setFetchReportLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});
  const [startMonth, setStartMonth] = useState(new Date().getMonth());
  const [endMonth, setEndMonth] = useState(new Date().getMonth() + 1);
  const [reports, setReports] = useState([]);

  const isSuperAdmin = userDetail?.levelName === "superadmin";

  useEffect(() => {
    onGetAnalytics();
  }, []);

  const onGetAnalytics = async () => {
    try {
      setFetchLoading(true);
      const response = await getAnalytics("module-mrm");
      if (!response.success) throw response.message;

      setFetchLoading(false);

      setAnalytics(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetAnalytics: ", error);
    }
  };

  if (isSuperAdmin) {
    return navigate("/home");
  }

  const options = {
    chart: {
      type: "spline",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      categories: analytics?.charts?.labels,
      labels: {
        formatter: function () {
          return moment(this.value).format('DD-MMM-YYYY');
        },
      },
    },
    yAxis: {
      title: {
        text: "Booking Ruang",
      },
    },
    tooltip: {
      headerFormat: null,
    },
    series: [
      {
        name: "Booking Ruang",
        // data: [525, 1134, 385, 102, 684, 1296, 1195, 666, 1382, 942, 1145, 467, 1351, 224, 1459, 1196, 688, 1084, 1466, 808, 994, 347, 1371, 420, 1131, 228, 713, 539, 663, 1238, 663],
        data: analytics.charts?.data || [],
        color: "rgb(37, 99, 235, .8)",
      },
    ],
  };

  // Placeholder options for the chart
  const placeholderOptions = {
    title: {
      text: "No Data Available",
    },
    series: [
      {
        data: [],
      },
    ],
  };

  const isDataEmpty =
    analytics.charts?.data.length === 0 &&
    analytics.charts?.labels.length === 0;

  const columReports = [
    { key: "id", title: "ID", render: (val, item, index) => index + 1 },
    { key: "name", title: "Ruangan" },
    { key: "title", title: "Event" },
    { key: "start_date", title: "Start Event", render: (val) => moment(val).format('DD-MMM-YYYY HH:mm')},
    { key: "end_date", title: "End Event", render: (val) => moment(val).format('DD-MMM-YYYY HH:mm')},
    { key: "employee", title: "Employee", render: (val) => val?.name} 
  ];

  const fetchReport = async () => {
    try {
      const timeNow = moment();
      const request = {
        startDate: timeNow
          .set("month", startMonth - 1)
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: timeNow
          .set("month", endMonth - 1)
          .endOf("month")
          .format("YYYY-MM-DD"),
      };

      setFetchReportLoading(true);
      const response = await getReportMRM(request);
      if (!response.success) throw response.message;

      console.log("response", response?.data)
      setFetchReportLoading(false);
      setReports(response?.data);
    } catch (error) {
      setFetchReportLoading(false);
      console.log("Error on fetchReport: ", error);
    }
  };

  return (
    <div className="p-6">
      <div className="flex gap-6">
        <CardCount
          title="Jumlah Booking Ruang"
          value={analytics?.totalMeetingRoomsInMonth || 0}
          icon={<FaHouseLaptop />}
          isLoading={fetchLoading}
          className={{
            container: "mb-6 from-emerald-300 to-teal-600",
          }}
        />
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={isDataEmpty ? placeholderOptions : options}
      />
      <div className="mt-6 bg-white p-4">
        <span className="text-lg font-bold">Tabel Report</span>

        <div className="mt-3 flex justify-between items-center">
          <div className="flex gap-1">
            <Select
              label="Start"
              placeholder="Pilih Bulan"
              options={monthsOptions}
              defaultValue={monthsOptions[startMonth - 1]}
              className={{
                container: "w-40",
                input: "h-10 pl-3 capitalize",
                suffix: "text-sky-700",
                option: "capitalize",
              }}
              onInputChange={(e) => setStartMonth(e.value)}
            />
            <Select
              label="End"
              placeholder="Pilih Bulan"
              options={monthsOptions}
              defaultValue={monthsOptions[endMonth - 1]}
              className={{
                container: "w-40",
                input: "h-10 pl-3 capitalize",
                suffix: "text-sky-700",
                option: "capitalize",
              }}
              onInputChange={(e) => setEndMonth(e.value)}
            />
          </div>
          <Button
            className="w-1/6 h-10"
            label="Generate"
            onClickBtn={fetchReport}
          />
        </div>
        <div className="mt-4">
          <Table
            loading={fetchReportLoading}
            columns={columReports}
            dataSource={reports}
            labelNotFound={`Data tidak tampil dari bulan ${moment()
              .subtract(1, "months")
              .format("MMMM")} sd ${moment().format("MMMM")}`}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardMrm;
