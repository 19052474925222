import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { FaUsersGear, FaChartSimple, FaBuilding, FaHouse, FaStairs } from "react-icons/fa6";
import { cn } from "../utils/helper";
import { useAppContext } from "../context/AppContextProvider";
import LogoImg from "./../assets/image/smart-office-logo-white.png";

const DefaultMenus = [
  {
    title: "Dashboard",
    icon: <FaChartSimple />,
    type: "menu",
    level: ["superadmin", "owner", "staff"],
    license: "standard",
    link: "/super-admin/dashboard",
  },
  {
    title: "Modul Owner",
    icon: <FaUsersGear />,
    type: "menu",
    level: ["superadmin"],
    license: "standard",
    link: "/super-admin/owner",
  }
]

const ModuleEmployeeMenus = [
  {
    title: "Dashboard",
    icon: <FaChartSimple />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-employee",
  },
  {
    title: "Employee",
    icon: <FaUsersGear />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-employee/employee",
  },
];

const ModuleFacilityMenus = [
  {
    title: "Dashboard",
    icon: <FaChartSimple />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-facility",
  },
  {
    title: "Manage Building",
    icon: <FaBuilding />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-facility/buildings",
  },
  {
    title: "Manage Floor",
    icon: <FaStairs />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-facility/floors",
  },
  {
    title: "Manage Room",
    icon: <FaHouse />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-facility/rooms",
  },
];

const ModuleMrmMenus = [
  {
    title: "Dashboard",
    icon: <FaChartSimple />,
    type: "menu",
    level: ["owner"],
    license: "standard",
    link: "/owner/module-mrm",
  }
];

const mappingMenus = {
  'default': DefaultMenus,
  'employee': ModuleEmployeeMenus,
  'facility': ModuleFacilityMenus,
  'meeting room management system': ModuleMrmMenus,
}

const Sider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetail, setStore, activeModule } = useAppContext();
  const [activeMenu, setActiveMenu] = useState(location.pathname);

  useEffect(() => {
    const moduleMenus = mappingMenus[activeModule] || mappingMenus['default'];
    
    const menuTitle = moduleMenus.find(menu => location.pathname === menu.link)?.title;
    setStore({ menuTitle });
  }, [])

  const onClickMenu = ({ title, link }) => {
    setStore({ menuTitle: title });
    setActiveMenu(link);
    navigate(link);
  }

  const renderMenus = () => {
    const moduleMenus = mappingMenus[activeModule] || mappingMenus['default'];
    return moduleMenus.map((menu, index) => {
      if (!menu.level.includes(userDetail?.levelName)) return null;

      return (
        <li
          key={index}
          className={cn(
            "flex gap-2 p-2 m-2 rounded-md text-white list-none cursor-pointer select-none hover:bg-white/10 hover:font-bold",
            { "bg-white text-sky-700 font-bold hover:bg-white hover:text-sky-700": activeMenu == menu.link}
          )}
          onClick={() => onClickMenu(menu)}
        >
          {menu.icon}
          <span className="text-sm">{menu.title}</span>
        </li>
      )
    })
  }


  const defaultURL = userDetail?.levelName === "superadmin" ? "/dashboard" : "/home"
  return (
    <aside className="min-w-[240px] min-h-screen bg-gradient-to-b from-sky-700 to-sky-500">
      <a href={defaultURL} className="flex justify-center p-6 text-center">
        <img alt="" src={LogoImg} className="h-9" />
      </a>
      <hr className="mx-4 mb-6 border-t-white/20" />
      {renderMenus()}
    </aside>
  )
}

export default Sider;