import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import moment from 'moment';

export const cn = (...classes) => twMerge(clsx(...classes));

export const periodLicenseValidator = (periodLicense) => {
  if (!periodLicense) return;

  const startDate = moment();
  const endDate = moment(periodLicense);
  const isAfterStartDate = endDate.isAfter(startDate);
  const differenceDay = endDate.diff(startDate, 'day');
  const rangeOfDay = isAfterStartDate ? Math.abs(Math.round(differenceDay)) : 0;
  return rangeOfDay;
}

export const addedDays = (date, days) => {
  if (!date && !days) return;

  const initialDate = new Date(date);
  const newDate = new Date(initialDate.getTime() + (days * 24 * 60 * 60 * 1000));
  return newDate.toISOString().slice(0, 19).replace("T", " ");
}

export const getAllDatesInMonth = (month, year = new Date().getFullYear()) => {
  const dates = [];
  const lastDay = new Date(year, month, 0).getDate();

  for (let day = 1; day <= lastDay; day++) {
    const newDate = new Date(year, month - 1, day);
    dates.push({ origin: newDate, formatted: newDate.getDate() })
  }

  return dates;
}

export const getBase64 = file => {
  return new Promise(resolve => {
    // Make new FileReader
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader?.result || "");
    };
  });
};

export const base64ToFile = (base64String, filename) => {
  const encodedContent = base64String.includes(',') ? base64String.split(',')[1] : base64String;
  // Decode base64 to binary
  const binaryString = atob(encodedContent);

  // Convert binary to ArrayBuffer
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from ArrayBuffer
  const blob = new Blob([uint8Array]);

  // Create a File from Blob
  const file = new File([blob], filename, { type: blob.type });
  return [file];
}