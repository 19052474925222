import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaPen } from "react-icons/fa6";
import { addedDays, cn } from "../../utils/helper";
import {
  getLicenseList,
  getUserList,
  updateUser,
  getModuleList,
} from "../../services/api.service";
import Table from "../../components/Table";
import TableActionButton from "../../components/TableActionButton";
import Pagination from "../../components/Pagination";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";
import SelectMultiple from "../../components/SelectMultiple";

const ManageOwner = () => {
  const [openEditOwner, setOpenEditOwner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [licenseOption, setLicenseOption] = useState([]);
  const [modulesOption, setModulesOption] = useState([]);
  const [ownerDetail, setOwnerDetail] = useState({});
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const userColumns = [
    { key: "id", title: "ID", render: (val, item, index) => index + 1 },
    { key: "name", title: "Fullname" },
    { key: "email", title: "Email" },
    { key: "phone_number", title: "Phone Number" },
    {
      key: "license",
      title: "License",
      render: (value) => {
        const { name } = value;
        return (
          <div
            className={cn(
              "capitalize px-2.5 py-1 font-semibold text-red-500 rounded-full bg-red-600/5",
              {
                "text-red-500 bg-red-600/5": name === "trial",
                "text-green-500 bg-green-600/5": name === "premium",
                "text-sky-500 bg-sky-600/5": name === "multibranch",
              }
            )}
          >
            {name}
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      render: (_, item) => (
        <TableActionButton
          hiddenView
          hiddenDelete
          hiddenPrintBarcode
          onEdit={() => {
            setOpenEditOwner(true);
            setOwnerDetail(item);
            onChange("license", item?.license?.name);
            onChange("last_period_license",moment(item?.period_license).format("DD-MM-YYYY"));
            onChange("max_employee", item?.config?.max_employee);
            onChange("max_building", item?.config?.max_building);
            onChange("max_floor", item?.config?.max_floor);
            onChange("max_room", item?.config?.max_room);
            onChange("modules", item?.modules.map((m) => m.module.name))
            onChange("modules.value", item?.modules.map((m) => m.module_id))
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    onGetUserList();
    onGetLicense();
    onGetModules();
  }, []);

  const onGetUserList = async () => {
    try {
      setFetchLoading(true);
      const response = await getUserList();
      if (!response.success) throw response.message;

      setFetchLoading(false);
      setUserList(response.data);
    } catch (error) {
      setFetchLoading(false);
      console.log("Error on onGetListUser: ", error);
    }
  };

  const onGetLicense = async () => {
    try {
      const response = await getLicenseList();
      if (!response.success) throw response.message;

      setLicenseOption(response.data);
    } catch (error) {
      console.log("Error on onGetLicense: ", error);
    }
  };

  const onGetModules = async () => {
    try {
      const response = await getModuleList();
      if (!response.success) throw response.message;

      setModulesOption(response.data);
    } catch (error) {
      console.log("Error on onGetModules: ", error);
    }
  };

  const onUpdateUser = async (data) => {
    if (updateLoading) return;
    try {
      const request = {
        id: ownerDetail?.id,
        licenseId: data?.license?.value || ownerDetail?.license_id,
        periodLicense: addedDays(ownerDetail?.period_license, data.days) || ownerDetail?.period_license,
        max_employee: parseInt(data?.max_employee) || ownerDetail?.config?.max_employee,
        max_building: parseInt(data?.max_building) || ownerDetail?.config?.max_building,
        max_floor: parseInt(data?.max_floor) || ownerDetail?.config?.max_floor,
        max_room: parseInt(data?.max_room) || ownerDetail?.config?.max_room,
        modules: data?.modules.value || ownerDetail?.modules.map((m) => m.module_id),
      };

      setUpdateLoading(true);
      const response = await updateUser(request);
      if (!response.success) throw response.message;

      setUpdateLoading(false);
      setOpenEditOwner(false);
      setValue("days", null);
      onGetUserList();
    } catch (error) {
      setUpdateLoading(false);
      console.log("Error on onUpdateUser: ", error);
    }
  };

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  };

  return (
    <div className="p-6">
      <div className="bg-white border border-gray-200 rounded-lg h-max shadow-3xl">
        <div className="p-4 font-bold bg-gray-100 border-b rounded-t-lg text-sky-700">
          User Owner List
        </div>
        <div className="p-4">
          <Table
            loading={fetchLoading}
            columns={userColumns}
            dataSource={userList}
          />
        </div>
        <div className="mb-4">
          <Pagination
            currentPage={currentPage}
            total={!fetchLoading ? userList?.length : 0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
        <Modal
          open={openEditOwner}
          onOpen={setOpenEditOwner}
          className={{ container: "p-0" }}
        >
          <form onSubmit={handleSubmit(onUpdateUser)} className="overflow-auto">
            <div className="flex items-center px-4 py-3 text-white bg-sky-600">
              <div className="p-1 text-sm border border-white rounded-lg">
                <FaPen />
              </div>
              <div className="ml-3 mr-1 text-lg">Edit</div>
              <div className="flex items-center">
                <h1 className="mx-1 text-sm">(&nbsp;{ownerDetail?.name}</h1>
                {ownerDetail?.name && ownerDetail?.email && "/"}
                <span className="mx-1 text-sm">
                  {ownerDetail?.email}&nbsp;)
                </span>
              </div>
            </div>
            <div className="px-8 py-6 flex flex-col gap-2">
              <div className="p-3 border-2 border-slate-300 rounded">
                <div className="mb-5">
                  <span className="text-sm">Konfigurasi License</span>
                </div>
                <div className="mb-5">
                  <Select
                    label="License"
                    placeholder="Pilih License"
                    options={licenseOption}
                    className={{
                      label: "text-gray-700",
                      input: "h-10 pl-3 capitalize",
                      suffix: "text-sky-700",
                      option: "capitalize",
                    }}
                    errorMessage={errors?.license?.message}
                    onInputChange={(e) => {
                      onChange("license", e.name);
                      onChange("license.value", e.id);
                    }}
                    {...register("license")}
                  />
                </div>
                <div className="flex mb-5 gap-x-3">
                  <div className="basis-1/2">
                    <Input
                      label="Last Period License"
                      placeholder="Last Period License"
                      className={{
                        label: "text-gray-700",
                        input: "h-10 pl-3",
                      }}
                      errorMessage={errors?.last_period_license?.message}
                      onInputChange={(e) =>
                        onChange("last_period_license", e.target.value)
                      }
                      disabled
                      {...register("last_period_license")}
                    />
                  </div>
                  <div className="basis-1/2">
                    <Input
                      label="Period License"
                      placeholder="Period License"
                      className={{
                        label: "text-gray-700",
                        input: "h-10 pl-3",
                      }}
                      errorMessage={errors?.days?.message}
                      onInputChange={(e) => onChange("days", e.target.value)}
                      {...register("days", {
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Mohon masukkan angka.",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="p-3 border-2 border-slate-300 rounded">
                <div className="mb-5">
                  <span className="text-sm">Konfigurasi User</span>
                </div>
                <div className="flex mb-5 gap-x-3">
                  <div className="basis-1/2">
                    <Input
                      label="Limit Pegawai"
                      placeholder="Limit count of pegawai"
                      className={{
                        label: "text-gray-700",
                        input: "h-10 pl-3",
                      }}
                      errorMessage={errors?.max_employee?.message}
                      onInputChange={(e) =>
                        onChange("max_employee", e.target.value)
                      }
                      {...register("max_employee", {
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Mohon masukkan angka.",
                        },
                      })}
                    />
                  </div>
                  <div className="basis-1/2">
                    <Input
                      label="Limit Bangunan"
                      placeholder="Limit count of bangunan"
                      className={{
                        label: "text-gray-700",
                        input: "h-10 pl-3",
                      }}
                      errorMessage={errors?.max_building?.message}
                      onInputChange={(e) =>
                        onChange("max_building", e.target.value)
                      }
                      {...register("max_building", {
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Mohon masukkan angka.",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="flex mb-5 gap-x-3">
                  <div className="basis-1/2">
                    <Input
                      label="Limit Lantai"
                      placeholder="Limit count of lantai"
                      className={{
                        label: "text-gray-700",
                        input: "h-10 pl-3",
                      }}
                      errorMessage={errors?.max_floor?.message}
                      onInputChange={(e) =>
                        onChange("max_floor", e.target.value)
                      }
                      {...register("max_floor", {
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Mohon masukkan angka.",
                        },
                      })}
                    />
                  </div>
                  <div className="basis-1/2">
                    <Input
                      label="Limit Ruangan"
                      placeholder="Limit count of ruangan"
                      className={{
                        label: "text-gray-700",
                        input: "h-10 pl-3",
                      }}
                      errorMessage={errors?.max_room?.message}
                      onInputChange={(e) =>
                        onChange("max_room", e.target.value)
                      }
                      {...register("max_room", {
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Mohon masukkan angka.",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="p-3 border-2 border-slate-300 rounded">
                <div className="mb-5">
                  <span className="text-sm">Konfigurasi Module</span>
                </div>
                <div className="mb-5">
                  <SelectMultiple
                    label="Modules"
                    placeholder="Pilih Module"
                    options={modulesOption}
                    className={{
                      label: "text-gray-700",
                      input: "h-10 pl-3 capitalize",
                      suffix: "text-sky-700",
                      option: "capitalize",
                    }}
                    errorMessage={errors?.modules?.message}
                    onInputChange={(modules) => {
                      onChange("modules", modules.map((m) => m.name));
                      onChange("modules.value", modules.map((m) => m.id));
                    }}
                    {...register("modules")}
                  />
                </div>
              </div>
              <Button
                label="submit"
                isLoading={updateLoading}
                className="w-1/3 h-10 mt-8 ml-auto"
              />
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default ManageOwner;
