import { Link } from "react-router-dom";
import { useAppContext } from "../../context/AppContextProvider";
import CardModule from "../../components/CardModule";

const Directory = () => {
  const { userDetail, setActiveModule } = useAppContext();
  const isOwner = userDetail?.levelName === "owner";
  const isEmployee = userDetail?.levelName === "employee";
  const isReceptionist = userDetail?.levelName === "receptionist";
  const modules = userDetail?.modules || [];

  const employeeModule = modules.filter((m) => [3].includes(m.module_id));

  return (
    <div className="p-7 grid gap-5 lg:p-10 lg:gap-10">
      <span className="text-xl lg:text-4xl">Welcome to Kantor Pintar</span>
      <div className="grid gap-5 lg:flex lg:gap-10">
        {isOwner &&
          modules.map((m, index) => (
            <Link
              to={`/owner${m.module.url}`}
              className="w-full lg:w-1/3"
              onClick={() => setActiveModule(m.module.name)}
              key={`card-module-${index}`}
            >
              <CardModule
                title={m.module.name}
                display={m.module.display}
                className={{ container: "w-full" }}
              />
            </Link>
          ))}

        {isOwner && !modules.length && (
          <span>Please Contact super admin to add the modules</span>
        )}

        {isReceptionist && (
          <span className="bg-red-400 text-slate-100 p-3 rounded font-light text-lg w-full">
            Features for receptionists in development
          </span>
        )}

        {isEmployee &&
          employeeModule.find(
            (m) => m.module.name === "meeting room management system"
          ) && (
            <Link
              to="/calendar"
              className="w-full lg:w-1/3"
              onClick={() => setActiveModule("employee-calendar")}
            >
              <CardModule
                title="calendar"
                display="Calendar"
                className={{ container: "w-full" }}
              />
            </Link>
          )}
      </div>
    </div>
  );
};

export default Directory;
