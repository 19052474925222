import Slider from "react-slick";

const contents = [
  {
    title: "Satu Integrasi Untuk Semua Kebutuhan Anda",
    details: "Berbagaienis aplikasi yang mendukung bisnis skala umkm dan insitusi nehingga terintegrasi dengan platform Awan Digital lainnya"
  },
  {
    title: "Pengeluaran Untuk Solusi Teknologi yang Lebih Cerdas",
    details: "Fokus kepada bisnis inti dan hemat biaya pengembangan aplikasi berulang melalui solusi cloud dan bundling platform Awan Digital"
  },
  {
    title: "Experience Pelanggan Yang Lebih Baik",
    details: "Berikan pengalaman terbaik kepada pelanggan dengan berbagai solusi Awan Digital"
  }
]

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    animation: false,
    cssEase: 'ease',
    fade: true
  };

  const renderSlides = () => {
    return contents.map((content, index) => (
      <div key={index} className="text-white">
        <h3 className="mb-3 text-2xl">{content.title}</h3>
        <span className="text-sm">{content.details}</span>
      </div>
    ))
  }

  return (
    <div className="slider-wrapper">
      <Slider {...settings}>{renderSlides()}</Slider>
    </div>
  );
}

export default SliderComponent;