import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaUser, FaEnvelope, FaMobile, FaBriefcase, FaMapLocationDot, FaLocationDot, FaBuilding, FaLock } from "react-icons/fa6";
import { registerSSO, userAvailabilitySSO } from "../../services/api.service";
import { cn } from "../../utils/helper";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import SliderComponent from "./../../components/Slider";
import AlertComponent from './../../components/Alert';
import SocialIcons from "./../../assets/json/social.json";
import BussinessCategory from "./../../assets/json/bussiness-category.json";
import Province from "./../../assets/json/province.json";
import City from "./../../assets/json/city.json";
import LogoImg from "./../../assets/image/smart-office-logo-black.png";

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [cityOptions, setCityOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, trigger, formState: { errors }, setValue, getValues, handleSubmit } = useForm();

  const onChange = (field, value) => {
    setValue(field, value, { shouldValidate: true });
  }

  const onCheckTermAndCondition = () => {
    setValue("termAndCondition", !getValues("termAndCondition"));
    trigger("termAndCondition");
  }

  const onCheckUserAvaibility = async (email) => {
    try {
      const responseSSOUser = await userAvailabilitySSO(email);
      return !!responseSSOUser?.data?.id;
    } catch (error) {
      return false;
    }
  }

  const onRegisterClick = async (data) => {
    if (isLoading) return;

    try {
      const request = {
        name: data?.fullName,
        email: data.email,
        password: data.password,
        password_confirmation: data?.confirmPassword,
        phone: data?.phoneNumber,
        province: data.province,
        city: data.city,
        business_category: data?.businessCategory,
        business_name: data?.businessName,
        product_id: 4 // must be 6
      }

      setLoading(true);
      const responseSSOUser = await onCheckUserAvaibility(data?.email);
      if (responseSSOUser) throw "User already exist!";

      const responseSSO = await registerSSO(request);
      if (responseSSO?.message !== "success") throw responseSSO.message;

      setLoading(false);
      setAlertOption({ open: true, type: "success", message: responseSSO.message });
      setTimeout(() => navigate("/login"), 2000);
    } catch (error) {
      setLoading(false);
      setAlertOption({ open: true, type: "error", message: error });
    }
  }

  function renderSocialIcon() {
    return SocialIcons.map((item, index) => {
      return (
        <a key={index} href={item.link} target="_blank" rel="noreferrer">
          <img src={require(`../../assets/svg/${item.icon}`)} alt={item.alt} />
        </a>
      )
    })
  }

  return (
    <div className="h-screen overflow-y-auto font-outfit">
      <div className="hidden fixed h-screen lg:w-[380px] lg:bg-[#ED5565] lg:flex lg:flex-col lg:justify-center lg:px-8">
        <img src={LogoImg} className="w-52" style={{ filter: "drop-shadow(0 0 10px rgba(255, 255, 255, 0.4))" }} alt="" />
        <SliderComponent />
        <div className="flex">{renderSocialIcon()}</div>
      </div>
      <div className="relative h-fit lg:w-[calc(100%-380px)] lg:ml-auto select-none">
        <AlertComponent
          type={alertOption?.type}
          open={alertOption?.open}
          message={alertOption?.message}
          onClose={setAlertOption}
        />
        <div className="py-8 mx-8">
          <Link to="/">
            <img src={LogoImg} alt="" className="w-24" />
          </Link>
        </div>
        <div className="flex justify-center py-8 mx-8">
          <form autoComplete="off" className="w-[480px] text-[#7D818B]" onSubmit={handleSubmit(onRegisterClick)}>
            <div className="mb-6 text-center">
              <h3 className="text-4xl text-[#0F1628]">Register</h3>
              <span className="text-base">Lengkapi form untuk membuat akun</span>
            </div>
            <div className="mb-5">
              <Input
                label="Nama Lengkap"
                icon={<FaUser />}
                placeholder="Masukkan Nama Lengkap"
                errorMessage={errors?.fullName?.message}
                onInputChange={(e) => onChange('fullName', e.target.value)}
                {...register("fullName", { required: "Nama Lengkap wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Input
                label="Email"
                icon={<FaEnvelope />}
                placeholder="Masukkan Email"
                errorMessage={errors?.email?.message}
                onInputChange={(e) => onChange('email', e.target.value)}
                {...register("email", {
                  required: "Email wajib diisi!",
                  pattern: { value: /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, message: "Alamat email tidak sesuai!" }
                })}
              />
            </div>
            <div className="mb-5">
              <Select
                label="Kategori Bisnis"
                icon={<FaBriefcase />}
                placeholder="Pilih Kategori Bisnis"
                options={BussinessCategory}
                className={{
                  prefix: "text-purple",
                  suffix: "text-purple",
                  option: "hover:bg-purple"
                }}
                errorMessage={errors?.businessCategory?.message}
                onInputChange={(e) => onChange('businessCategory', e.name)}
                {...register("businessCategory", { required: "Kategori Bisnis wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Input
                label="Nama Bisnis"
                icon={<FaBuilding />}
                placeholder="Masukkan Nama Bisnis"
                errorMessage={errors?.businessName?.message}
                onInputChange={(e) => onChange('businessName', e.target.value)}
                {...register("businessName", { required: "Nama Bisnis wajib diisi!" })}
              />
            </div>
            <div className="mb-5">
              <Input
                label="No. Handphone"
                icon={<FaMobile />}
                placeholder="Ch. 628***"
                errorMessage={errors?.phoneNumber?.message}
                onInputChange={(e) => onChange('phoneNumber', e.target.value)}
                {...register("phoneNumber", { required: "No. Handphone wajib diisi!", pattern: { value: /^[0-9]*$/, message: "No. Handphone tidak sesuai!" } })}
              />
            </div>
            <div className="lg:flex gap-x-[10px] mb-5">
              <div className="basis-6/12">
                <Select
                  label="Provinsi"
                  icon={<FaMapLocationDot />}
                  placeholder="Pilih Provinsi"
                  options={Province}
                  className={{
                    prefix: "text-purple",
                    suffix: "text-purple",
                    option: "hover:bg-purple"
                  }}
                  errorMessage={errors?.province?.message}
                  onInputChange={(e) => {
                    onChange('province', e.name);
                    setCityOptions(City[e.name]);
                  }}
                  {...register("province", { required: "Provinsi wajib diisi!" })}
                />
              </div>
              <div className="basis-6/12">
                <Select
                  label="Kota"
                  icon={<FaLocationDot />}
                  placeholder="Pilih Kota"
                  options={cityOptions}
                  className={{
                    prefix: "text-purple",
                    suffix: "text-purple",
                    option: "hover:bg-purple"
                  }}
                  disabled={!cityOptions?.length}
                  errorMessage={errors?.city?.message}
                  onInputChange={(e) => onChange('city', e.name)}
                  {...register("city", { required: "Kota wajib diisi!" })}
                />
              </div>
            </div>
            <div className="mb-5">
              <Input
                type={showPassword ? "text" : "password"}
                label="Password"
                icon={<FaLock />}
                placeholder="Masukkan Password"
                errorMessage={errors?.password?.message}
                showPassword={showPassword}
                onShowPassword={setShowPassword}
                onInputChange={(e) => {
                  onChange('password', e.target.value);
                  trigger("confirmPassword");
                }}
                {...register("password", {
                  required: "Password wajib diisi!",
                  validate: (val) => val === getValues("confirmPassword") || "Password tidak sama dengan Konfirmasi password!"
                })}
              />
            </div>
            <div className="mb-5">
              <Input
                type={showConfirmPassword ? "text" : "password"}
                label="Konfirmasi Password"
                icon={<FaLock />}
                placeholder="Masukkan Konfirmasi Password"
                errorMessage={errors?.confirmPassword?.message}
                showPassword={showConfirmPassword}
                onShowPassword={setShowConfirmPassword}
                onInputChange={(e) => {
                  onChange('confirmPassword', e.target.value);
                  trigger("password");
                }}
                {...register("confirmPassword", {
                  required: "Konfirmasi password wajib diisi!",
                  validate: (val) => val === getValues("password") || "Konfirmasi password tidak sama dengan Password baru!"
                })}
              />
            </div>
            <div className={cn("flex flex-wrap items-center gap-2 text-sm", { "mt-8": errors?.confirmPassword })} onClick={onCheckTermAndCondition}>
              <input
                type="checkbox"
                className="purple-checkbox appearance-none w-4 h-4 border-2 border-[#5557DB] rounded"
                {...register("termAndCondition", { required: "Syarat dan Ketentuan wajib disetujui!" })}
              />
              <span className="text-sm cursor-pointer">
                Saya telah membaca dan menyetujui
                <Link to="https://awandigital.id/term_condition.html" target="_blank" className="text-[#5557DB]" onClick={(e) => e.stopPropagation()}>
                  &nbsp; syarat dan ketentuan &nbsp;
                </Link>
                yang berlaku.
              </span>
            </div>
            {errors.termAndCondition && <div className="py-1 -mb-5 text-sm text-red-500">{errors.termAndCondition?.message}</div>}
            <div className="mt-8 mb-28">
              <Button label="Register" isLoading={isLoading} className="bg-purple hover:bg-purple/75" />
              <div className="mt-5 text-sm text-center">Sudah punya akun? &nbsp;<Link to="/login" className="text-[#5557DB]">Login</Link></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;