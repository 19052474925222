import axios from "axios";
import { destroyToken, getLocalToken, getSSOToken, removeLocalStorage } from "./jwt.service";

const validateUrl = (url) => {
  if (url && (url.includes("http://") || url.includes("https://"))) {
    return { baseURL: url, token: getSSOToken() ? `Bearer ${getSSOToken()}` : "" };
  }
  return { baseURL: `${process.env.REACT_APP_API_URL}/api/`, token: getLocalToken() ? `Bearer ${getLocalToken()}` : "" };
}

export const initializeAxios = () => {
  // Request interceptor
  axios.interceptors.request.use((config) => {
    config.baseURL = validateUrl(config.url).baseURL;
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Source: process.env.REACT_APP_URL,
      Name: "kantorpintar"
    };
    config.headers.Authorization = validateUrl(config.url).token;
    return config;
  }, (error) => Promise.reject(error));

  // Response interceptor
  axios.interceptors.response.use((response) => {
    return (response?.data?.data || response?.data?.message) ? response.data : response;
  }, (error) => {
    const message = error?.response?.data?.message || error?.response?.statusText || error?.message || error;
    if (message?.includes("Unauthenticated")) {
      destroyToken();
      removeLocalStorage("user");
      window.location.href = "/login";
    }
    return Promise.reject(message);
  });
}

export const userAvailabilitySSO = async (email) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/user-available`, { email });
}

export const registerSSO = async (requestData = {}) => {
  const headers = {
    ApiSource: process.env.REACT_APP_API_URL
  }
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/register_sso`, requestData, { headers: headers });
}

export const forgotPasswordSSO = async (email) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_URL}/api/forgot-password`, { email });
}

export const authRegister = async (requestData = {}) => {
  return await axios.post("auth/register", requestData);
}

export const authLogin = async (requestData = {}) => {
  return await axios.post("auth/login", requestData);
}

export const authChangePassword = async (requestData = {}) => {
  return await axios.put("auth/change-password", requestData);
}

export const authResetPassword = async (requestData = {}) => {
  return await axios.put("auth/reset-password", requestData);
}

export const getAnalytics = async (type) => {
  return await axios.get(`analytics/${type}`);
}

export const getUserList = async () => {
  return await axios.get("user/list");
}

export const updateUser = async (requestData = {}) => {
  return await axios.put("user/update", requestData);
}

export const getLicenseList = async () => {
  return await axios.get("user/license");
}

export const getModuleList = async () => {
  return await axios.get("user/modules");
}

export const getEmployeeList = async () => {
  return await axios.get("user/employee");
}

export const getEmployeeDetail = async (id) => {
  return await axios.get(`user/employee/detail/${id}`);
}

export const createUpdateEmployee = async (requestData = {}) => {
  return await axios.post("user/employee/create-update", requestData);
}

export const resetPasswordEmployee = async (email) => {
  return await axios.post(`user/employee/reset-password`, { email });
}

export const verifiedEmployee = async (requestData) => {
  return await axios.post(`user/employee/verified`, requestData);
}

export const deleteEmployee = async (id) => {
  return await axios.delete(`user/employee/delete/${id}`);
}

// API Building
export const getBuildingList = async () => {
  return await axios.get(`facility/buildings`);
}

export const upsertBuilding = async (requestData = {}) => {
  return await axios.post(`facility/buildings/upsert`, requestData);
}

export const deleteBuilding = async (id) => {
  return await axios.delete(`facility/buildings/${id}`);
}

// API Floor
export const getFloorList = async () => {
  return await axios.get(`facility/floors`);
}

export const upsertFloor = async (requestData = {}) => {
  return await axios.post(`facility/floors/upsert`, requestData);
}

export const deleteFloor = async (id) => {
  return await axios.delete(`facility/floors/${id}`);
}

// API Room
export const getRoomList = async () => {
  return await axios.get(`facility/rooms`);
}

export const upsertRoom = async (requestData = {}) => {
  return await axios.post(`facility/rooms/upsert`, requestData);
}

export const deleteRoom = async (id) => {
  return await axios.delete(`facility/rooms/${id}`);
}

// API Report
export const getReportMRM = async (requestData = {}) => {
  return await axios.post(`report/module-management-room`, requestData);
}

// API Module Meeting Room 
export const getMeetingRoomList = async () => {
  return await axios.get(`meeting-room`);
}

export const upsertMeetingRoom = async (requestData = {}) => {
  return await axios.post(`meeting-room/upsert`, requestData);
}

export const deleteMeetingRoom = async (id) => {
  return await axios.delete(`meeting-room/${id}`);
}